import jwt_decode from 'jwt-decode';


function jwtVerifyAndDecode(jwtString) {
    return new Promise((resolve, reject) => {
        if (!jwtString) {
            return reject('jwt must be provided');
        }

        if (typeof jwtString !== 'string') {
            return reject('jwt must be a string');
        }

        var parts = jwtString.split('.');

        if (parts.length !== 3) {
            return reject('not a standard jwt');
        }
        let decoded_token = jwt_decode(jwtString);
        return resolve(decoded_token);
    })
}

export  default  jwtVerifyAndDecode;
