import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import DatabseUploadComponent from '../components/databaseupload/DatabaseUploadSecure';
import BrowserBanner from "../components/reuseable/BrowserBanner";


const DatabaseUploadSecure = () => (
  <>
  <BrowserBanner />
  <Layout mode={true} sticky_footer={false}>
    <SEO title="Amber by inFeedo | Database Upload" />
    <DatabseUploadComponent/>
  </Layout>
  </>
)

export default DatabaseUploadSecure;